import React, {useState, useEffect} from 'react';
import Katex from '../katex/Katex';
import ExternalImage from './ExternalImage'
// import 'katex/dist/katex.min.css';

const replaceCRLF = (t, id, imgs) => {
  const _id = id != null ? id : "tmp";

  let parts = escape(t).split("\n");
  let len = parts.length;
  return parts.map((item, idx) => {
    return (
      <span key={_id + "_other_" + idx}>
        {item.indexOf("{image") !== -1 ? (
          <ImageWrap text={item} imgUrls={imgs} />
        ) : (
          item
        )}
        {idx < len - 1 ? <br /> : null}
      </span>
    );
  });
};

const ImageWrap = ({ text, imgUrls }) => {
  const startIndex = text.indexOf("{image");
  const endIndex = text.indexOf("}");

  let sliceString = text.substring(startIndex, endIndex + 1);
  let sizeString = null;

  sliceString = sliceString.replace(/{/g, "").replace(/}/g, "").split(", ");
  const imageIndex = sliceString[0].split("=")[1];
  sizeString = sliceString[1] ? sliceString[1].replace(/scale=/, "") : null;

  if (!imgUrls) {
    return <font color="red">[이미지를 찾을 수 없습니다]</font>;
  } 
  const url = imgUrls[imageIndex];
  if (!url) {
    return <font color="red">[이미지를 찾을 수 없습니다]</font>;
  }
  console.log("imgs: ", imgUrls)
  return (
    <ExternalImage imgSrc={url} scale={sizeString ? Number(sizeString) : 1} />
  );
};

const escape = (t) => {
  return t.replace(/＝/g, "=")
    .replace(/－/g, "-")
    .replace(/＋/g, "+")
    .replace(/𝑚/g, "m")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, "\"");
}

export function formatText(text, id, imgs) {
  const _id = id != null ? id : 'tmp';

  if (text == null) {
    return '';
  }

  const _t = text.normalize ? text.normalize("NFC") : text;
  let r;
  let katexIndices = [];
  let lastIndex = 0;

  const katexReg = new RegExp(/\$\$([^$]+)\$\$/igm);

  while (r = katexReg.exec(_t)) {
    if (r.index < lastIndex) {
      continue;
    }

    katexIndices.push(r.index, katexReg.lastIndex);
    lastIndex = katexReg.lastIndex;
  }
  katexIndices.push(_t.length);

  let lastIdx = 0;
  let result;

  if (katexIndices.length > 0) {
    return katexIndices.map((i, seq) => {
      let pre = _t.substring(lastIdx, i);
      lastIdx = i;

      const key = _id + '_kat_' + seq;

      const r = katexReg.exec(escape(pre));

      if (r != null && r.index >= 0) {
        // return <InlineMath key={key} math={r[1]}
        //     renderError={(err) => {
        //         const exp = r[1];
        //         const largeExp = new RegExp(/\\large(\s*)({(.+)})/igm);

        //         if (exp.match(/\\large(\s*)({(.+)})/)) {
        //             return exp.replace(/\\large(\s*)({(.+)})/igm, "$3")
        //         }
        //         console.log(err);
        //         return r[1]
        //     }} />
        return <Katex key={key} math={r[1]} />
      } else {
        return <span key={key}>{replaceCRLF(pre, id, imgs)}</span>
      }
    });
  } else {
    return <span>{replaceCRLF(_t, id, imgs)}</span>
  }
}

// []{{ * }} -> {{*}}
export function replaceSquareBracketPattern(str) {
  const regex = /\[\](\{\{.*?\}\})/g;
  return str.replace(regex, "$1");
}

export function formatProblem(problem, id, producer) {
  const _id = id != null ? id : 'tmp';

  let _p = problem.normalize ? problem.normalize("NFC") : problem;
  _p = replaceSquareBracketPattern(_p);

  const totalReg = /(({\{(.*?)}})|(<\/?frac>)|(\/\/))/igm;
  const answerReg = /{\{(.*?)}}/igm;

  let r;
  let answerIndices = [];

  const parser = new RegExp(totalReg);
  let lastIdx = -1;
  while (r = parser.exec(_p)) {
    if (r.index === lastIdx) {
      answerIndices.push(parser.lastIndex);
    } else {
      answerIndices.push(r.index, parser.lastIndex);
    }
    lastIdx = parser.lastIndex;
  }
  answerIndices.push(_p.length);

  lastIdx = 0;

  let answerIdx = 0;

  if (answerIndices.length > 0) {
    let frac = false;
    let fracStartIdx = -1;

    let fracs = [];

    const reg = new RegExp(answerReg);

    let skip = 0;

    let result = answerIndices.map((i, seq) => {
      let pre = _p.substring(lastIdx, i);

      lastIdx = i;

      const key = _id + '_prob_' + seq;

      const _seq = seq - skip;

      const r = reg.exec(pre);

      if (r != null && r.index >= 0) {
        const a = producer(answerIdx, r[1]);
        answerIdx++;
        return a;
      } else {
        const t = pre.trim();

        if (t === '') {
          skip++;
          return null;
        }

        if (t === '<frac>') {
          frac = true;
          fracStartIdx = _seq;
          return '<frac>';
        } else if (t === '//') {
          return '<sep>'
        } else if (t === '</frac>') {
          if (frac) {
            frac = false;
            fracs.push([fracStartIdx, _seq]);
            return '</frac>';
          }
        }

        return <span key={key}>{formatText(pre, id)}</span>
        // return pre;
      }
    }).filter(o => o != null);

    for (let i = 0; i < fracs.length; i++) {
      const frac = fracs[i];

      const startIdx = frac[0];
      const endIdx = frac[1];

      const numerator = [];
      const denominator = [];

      let sep = false;

      for (let j = startIdx + 1; j < endIdx; j++) {
        if (result[j] === '<sep>') {
          sep = true;
          continue;
        }

        if (result[j] === ""
          || result[j] === undefined
        ) {
          continue;
        } else if (result[j] === '<frac>') {
          continue;
        } else if (result[j] === '</frac>') {
          sep = false;
          continue;
        }
        if (!sep) {
          numerator.push(result[j]);
        } else {
          denominator.push(result[j]);
        }
      }

      const component = (
        <div key={_id + i} className="problem-frac-wrap">
          <div className="problem-frac-numerator">
            {numerator}
          </div>
          <div className="problem-frac-denominator">
            {denominator}
          </div>
        </div>
      )

      const len = (endIdx - startIdx + 1);

      result.splice(startIdx, len, component);

      for (let j = i + 1; j < fracs.length; j++) {
        fracs[j][0] = fracs[j][0] - len + 1;
        fracs[j][1] = fracs[j][1] - len + 1;
      }
    }

    return result;
  } else {
    return <span>{formatText(_p, id)}</span>
  }
}
