import React, { Component } from "react";
import classNames from "classnames";
// import '../css/Quiz.scss';
import { isMobileOnly } from "react-device-detect";
import debounce from "lodash.debounce";

// const isMobileSize = window.innerWidth <= 768;

class ImageSelectLayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      selected: false,
      isMobileSize: window.innerWidth <= 768
    };

    this.handleResize = debounce(this.handleResize, 100);
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      isMobileSize: window.innerWidth <= 768
    });
  };

  handleMouseOver = e => {
    this.setState({
      enabled: true
    });

    this.props.onMouseOver();
  };

  handleMouseLeave = e => {
    this.setState({
      enabled: false
    });

    this.props.onMouseLeave();
  };

  handleSelect = e => {
    const { selected } = this.props;

    this.props.onSelected(!selected);
  };

  render() {
    const {
      image,
      rect,
      solvedData,
      selected,
      showReallyTrue,
      onSelected,
      isMobileSize
    } = this.props;
    const { enabled } = this.state;

    const x = rect.x < 0 ? 0 : rect.x;
    const y = rect.y < 0 ? 0 : rect.y;
    const width = rect.x + rect.width > 100 ? 100 - rect.x : rect.width;
    const height = rect.y + rect.height > 100 ? 100 - rect.y : rect.height;

    let mRect = null;
    let mStyle = null;
    if (rect.mobile) {
      mRect = rect.mobile;
      const mX = mRect.x < 0 ? 0 : mRect.x;
      const mY = mRect.y < 0 ? 0 : mRect.y;
      const mWidth = mRect.x + mRect.width > 100 ? 100 - mRect.x : mRect.width;
      const mHeight =
        mRect.y + mRect.height > 100 ? 100 - mRect.y : mRect.height;

      mStyle = {
        left: mX + "%",
        top: mY + "%",
        width: mWidth + "%",
        height: mHeight + "%"
      };
    }

    const style = {
      left: x + "%",
      top: y + "%",
      width: width + "%",
      height: height + "%"
      // backgroundRepeat: 'no-repeat',
      // backgroundImage: enabled || selected ? image.signedUrl : null,
    };

    // console.log(mStyle);

    return (
      <div
        className={classNames("imageSelectArea", {
          "is-chk": selected,
          "is-true":
            selected &&
            solvedData.solved &&
            solvedData.correctAnswers.includes(rect.key),
          "is-false":
            selected &&
            solvedData.solved &&
            !solvedData.correctAnswers.includes(rect.key),
          "is-reallytrue":
            showReallyTrue && solvedData.correctAnswers.includes(rect.key),
          "none-event": solvedData && solvedData.solved
        })}
        // style={style}
        style={
          (isMobileOnly || isMobileSize) && mStyle && mRect ? mStyle : style
        }
        // onMouseOver={this.handleMouseOver}
        // onMouseLeave={this.handleMouseLeave}
        onClick={() => onSelected(!selected)}
      ></div>
    );
  }
}

export default ImageSelectLayer;
